/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Use consistent styling
import 'sanitize.css/sanitize.css';
// Import root app
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'
// Initialize languages
import './locales/i18n';

// const bugAPI = process.env.REACT_APP_BUGSNAG_API_KEY || ''

// Bugsnag.start({
//   apiKey: bugAPI,
//   appVersion: "1.0.0",
//   enabledReleaseStages: [ 'staging','production' ],
//   releaseStage: process.env.REACT_APP_ENV,
//   plugins: [new BugsnagPluginReact()]
// })

// const snag = Bugsnag.getPlugin('react')
// let ErrorBoundary:any = null
// if (snag !== undefined) {
//   ErrorBoundary = snag.createErrorBoundary(React)
// }

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
// if ((module as any).hot) {
//   (module as any).hot.accept()
//   (module as any).hot.accept(['./locales/i18n'], () => {
//     // No need to render the App again because i18next works with the hooks
//   });
// }
