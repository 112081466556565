/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { Alert, Snackbar, Box } from '@mui/material';
import { GlobalStyle } from 'styles/global-styles';
import './../styles/global-styles';
import './../styles/_global-styles.scss';
import { Account } from './pages/Account/Loadable';
import { Dashboard } from './pages/Dashboard/Loadable';
import { SignUp } from './components/SignUp/Loadable';
import { Login } from './pages/Login/Loadable';
import { Projects } from './pages/Projects/Loadable';
import { Organization } from './pages/Organization/Loadable';
import { Signup } from './pages/Signup/Loadable';
import { ForgotPW } from './pages/Login/ForgotPW';
import { ResetPW } from './pages/Login/ResetPW';

import { Integration } from './pages/Login/Integration';
import { Invite } from './pages/Login/Invite';
import { AccessDenied } from './components/AccessDenied/Loadable';
import styled, { keyframes } from 'styled-components/macro';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { useAppSlice } from './slice';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectLoading,
  selectPayload,
  selectError,
  selectUser,
  selectToken,
  selectSuccess,
  selectAppAlertOpen,
  selectAppAlertSeverity,
  selectAppAlert,
  selectNotifications,
} from './slice/selectors';

import { Storyblok } from './storyblok';

export enum Role {
  SuperAdmin = 6,
  SuperUser = 5,
  Partner = 4,
  Executive = 3,
  Manager = 2,
  Contributor = 1,
}

const PrivateRoute = ({ children, roles, greaterThanRole }: {
  children: JSX.Element
  roles?: Array<Role>
  greaterThanRole?: number
}) => {
  let location = useLocation()
  const tokenCookie = localStorage.getItem('token')
  const userCookie = localStorage.getItem('user')
  const user = userCookie ? JSON.parse(userCookie) : null

  let userHasRequiredRole = true

  if (roles) {
    userHasRequiredRole = user && roles.includes(user.role.id) ? true : false
  } else if (greaterThanRole) {
    userHasRequiredRole = user && user.role.id >= greaterThanRole ? true : false
  }

  if (!tokenCookie && !userCookie) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (!userHasRequiredRole) {
    return <Navigate to="/access-denied" state={{ from: location }} />
  }

  return children
}

// const PrivateAdminRoute = ({ component: Component, user: user, ...rest }) => {
//   // Add your own authentication on the below line.
//   const isLoggedIn = localStorage.getItem("user")
//   let parsedUser:any = null;
//   let isAdmin = false;
//   if (isLoggedIn) {
//     parsedUser = JSON.parse(isLoggedIn)
//     isAdmin = parsedUser.role_id >= 2
//   }
//
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         isLoggedIn !== undefined && isLoggedIn !== null ? (
//           <>
//           {isAdmin
//             ? (<Component {...props} />)
//             : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
//           }
//           </>
//         ) : (
//           <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
//         )
//       }
//     />
//   )
// }

export function App() {
  const { i18n } = useTranslation();
  const { actions } = useAppSlice();
  const dispatch = useDispatch();
  const authUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const errors = useSelector(selectError);
  const success = useSelector(selectSuccess);
  const payload = useSelector(selectPayload);
  const notifications = useSelector(selectNotifications);
  const isAlertOpen = useSelector(selectAppAlertOpen);
  const alertMsg = useSelector(selectAppAlert);
  const alertSeverity = useSelector(selectAppAlertSeverity);

  React.useEffect(() => {
    const userCookie = localStorage.getItem('user');
    const expires_at = localStorage.getItem('expires_at');
    const orig_iat = localStorage.getItem('orig_iat');
    const tokenCookie = localStorage.getItem('token');
    const now = new Date();

    if (tokenCookie !== null && userCookie !== null && expires_at !== null) {
      const parsedUser = JSON.parse(userCookie);
      let now = new Date();
      let expiresAt = new Date(parseInt(expires_at) * 1000);
      if (now > expiresAt) {
        setTimeout(() => dispatch(actions.logout()), 100);
      }

      dispatch(actions.set_authUser(parsedUser));
      dispatch(actions.set_authToken(tokenCookie));
    }
  }, []);

  function handleCloseAlert(e: any) {
    setTimeout(() => dispatch(actions.closeNotify()), 100);
  }

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Solvr"
        defaultTitle="Solvr - Security compliance made easy."
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Security compliance made easy." />
      </Helmet>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isAlertOpen}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          sx={{
            width: '100%',
            fontSize: 18,
            alignItems: 'center',
            borderRadius: '25px',
          }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Routes>
        {/* // Main Views */}
        <Route path="/login" element={<Login/>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/forgot" element={<ForgotPW/>}/>
        <Route path="/reset/:token/confirm" element={<ResetPW/>}/>

        <Route path="/solvr" element={<Integration/>}/>
        <Route path="/invite" element={<Invite/>}/>
        <Route
          path="/"
          element={
            <PrivateRoute greaterThanRole={Role.Contributor}>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/projects"
          element={
            <PrivateRoute greaterThanRole={Role.Manager}>
              <Projects />
            </PrivateRoute>
          }
        />

        <Route
          path="/organization"
          element={
            <PrivateRoute greaterThanRole={Role.Manager}>
              <Organization />
            </PrivateRoute>
          }
        />

        <Route
          path="/account"
          element={
            <PrivateRoute greaterThanRole={Role.Contributor}>
              <Account />
            </PrivateRoute>
          }
        />
        <Route path="/access-denied" element={<AccessDenied/>} />
        <Route element={<NotFoundPage/>} />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
